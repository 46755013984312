<template>
  <div class="flex h-full flex-col gap-8 pt-4">
    <div class="flex w-1/2 flex-col gap-4 pr-6">
      <div class="flex flex-col gap-2 text-start">
        <span class="text-subhead-2">Mapping rule name</span>
        <UiInputTextField
          id="name"
          v-model="modelValue.name"
          name="name"
          placeholder="Add rule name"
          :error="useGetFieldErrors(v$, ['name'])"
        />
      </div>
    </div>
    <div class="mb-4 flex w-full flex-row gap-12">
      <RotationMappingCreateEditNewRules v-model="modelValue.rules" :v="v$" />
      <RotationMappingCreateEditNewRules v-model="modelValue.rulesOr" union="or" :v="v$" />
    </div>
    <div class="mt-auto flex flex-row items-center gap-4">
      <div v-if="v$.rules.$errors.length" class="flex flex-row items-start gap-2 text-error-100">
        <UiIcon name="info-circle-filled" />
        <div class="text-caption-2 text-left">
          <span>Oops, Looks like you didn't complete all parameters.</span> <br />
          <span> Each block consists of at least one full condition.</span>
        </div>
      </div>
      <div class="ml-auto flex flex-row items-center gap-4">
        <UiButtonBase id="cancel" type="secondary" @click="$emit('cancel')">Cancel</UiButtonBase>
        <UiButtonBase id="next" @click="next">Next</UiButtonBase>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import type { Mapping } from '~/types'

const emits = defineEmits(['cancel', 'next'])

const modelValue = defineModel<Mapping>({ required: true })

const rules = computed(() => ({
  name: { required: helpers.withMessage('Please add a rule name', required) },
  rules: {
    $each: helpers.forEach({
      rule_name: { required: helpers.withMessage(' ', required) },
      rule_value: { required: helpers.withMessage(' ', required) },
    }),
    validateRules: helpers.withMessage(
      'At least one rule must be added',
      (value: any) => value.length > 0 || modelValue.value.rulesOr!.length > 0
    ),
  },
  rulesOr: {
    $each: helpers.forEach({
      rule_name: { required: helpers.withMessage(' ', required) },
      rule_value: { required: helpers.withMessage(' ', required) },
    }),
    validateRules: helpers.withMessage(
      'At least one rule must be added',
      (value: any) => value.length > 0 || modelValue.value.rules?.length > 0
    ),
  },
}))

const v$ = useVuelidate(rules, modelValue)

const next = async () => {
  if (!(await v$.value.$validate())) return

  emits('next')
}
</script>

<style scoped></style>
